import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import BottomLayout from "@/layout/BottomLayout";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  Button,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/customer-success`}
      title="Level Up Customer Relationship and Boost Up sells | Airgram for Customer Success Team"
      description="Deliver true value to more customers effortlessly. Airgram helps facilitate the onboarding process, level up customer relationship through real-time transcription, timestamped notes and HD video recordings."
    />
  );
};

const { useState } = React;

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Better customer calls, happier customers, and more renewals"
          buttonText="Get Started Free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Improve retention and boost up sells with increasing user satisfaction
        </Text>
        <Text textAlign="center" fontSize={18}>
          Deliver true value out of your service or product to more customers
          effortlessly.
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Facilitate the onboarding process"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1588x942/217c67bead/usecasecustomersuccess1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Help customers adopt the product more quickly. Keep the customer
              call running smoothly with live transcription. Speaker detection
              helps identify who speaks what. Detect potential action items from
              the transcript in real time. Leave the manual note-taking work to
              your multilingual AI assistant.
            </Text>
            <Text fontSize={18}>
              Customers can solve problems independently by watching video
              recordings. You can follow up on more customers to accelerate your
              business.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Level up your CRM workflow"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1377x990/09ed9d3bc5/usecasecustomersuccess2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              In tracking customers' data, Airgram serves as a flexible right
              hand. Besides using Salesforce, Hubspot, or Pipedrive, you can get
              rich meeting information as a great addition while connecting with
              customers - transcript, video recording, timestamped notes, AI
              entity extraction.
            </Text>

            <Text fontSize={18}>
              Gain a deeper understanding of high-value customers for better
              customer engagement and sales.
            </Text>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Make a bigger contribution to the customer success strategy"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1452x918/9352c7484d/usecasecustomersuccess3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Enhance existing support documentation and sales activities for
              your team. Share your meeting records via Slack, Notion, or export
              them as Word, Google Docs. So your team can learn and analyze
              customer feedback to improve their work.
            </Text>
            <Text fontSize={18}>
              Make customers happier to reinforce your team’s customer success
              strategy. Effectively reduce churn and renew more contracts.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
